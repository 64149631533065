import React, { useCallback } from 'react'
import { useTranslation } from 'cng-web-lib'
import Namespace from '../../../constants/locale/Namespace'
import FdaPNKeys from '../../../constants/locale/key/Fdapn'

const FdaPNTranslationContext = React.createContext()

const FdaPNTranslationConsumer = FdaPNTranslationContext.Consumer

const FdaPNTranslationProvider = ({ children }) => {
    const { translate } = useTranslation([
        Namespace.FDAPN
    ])

    const translation = {
        FDAPN: {
            title: translate(Namespace.FDAPN, FdaPNKeys.TITLE),
            pnHeader: translate(Namespace.FDAPN, FdaPNKeys.PN_HEADER),
            updateButton: translate(Namespace.FDAPN, FdaPNKeys.UPDATE_BUTTON),
            saveButton: translate(Namespace.FDAPN, FdaPNKeys.SAVE_BUTTON),
            submitButton: translate(Namespace.FDAPN, FdaPNKeys.SUBMIT_BUTTON),
            editButton: translate(Namespace.FDAPN, FdaPNKeys.EDIT_BUTTON),
            cloneButton: translate(Namespace.FDAPN, FdaPNKeys.CLONE_BUTTON),
            deleteButton: translate(Namespace.FDAPN, FdaPNKeys.DELETE_BUTTON),
            discardButton: translate(Namespace.FDAPN, FdaPNKeys.DISCARD_BUTTON),
            viewFdaPN: translate(Namespace.FDAPN, FdaPNKeys.VIEW_FDAPN),
            editSentRecords: translate(Namespace.FDAPN, FdaPNKeys.EDIT_SENT_RECORDS),
            deleteRecords: translate(Namespace.FDAPN, FdaPNKeys.DELETE_RECORDS),
            id: translate(Namespace.FDAPN, FdaPNKeys.ID),
            createdDateTime: translate(Namespace.FDAPN, FdaPNKeys.CREATED_DATE_TIME),
            submittedDateTime: translate(Namespace.FDAPN, FdaPNKeys.SUBMITTED_DATE_TIME),
            acctId: translate(Namespace.FDAPN, FdaPNKeys.ACCT_ID),
            corpid: translate(Namespace.FDAPN, FdaPNKeys.CORPID),
            referenceQualifier: translate(Namespace.FDAPN, FdaPNKeys.REFERENCE_QUALIFIER),
            referenceIdNum: translate(Namespace.FDAPN, FdaPNKeys.REFERENCE_ID_NUM),
            pnConfirmationNumber: translate(Namespace.FDAPN, FdaPNKeys.PN_CONFIRMATION_NUMBER),
            anticipatedArrivalDate: translate(Namespace.FDAPN, FdaPNKeys.ANTICIPATED_ARRIVAL_DATE),
            anticipatedArrivalTime: translate(Namespace.FDAPN, FdaPNKeys.ANTICIPATED_ARRIVAL_TIME),
            arrivalLocation: translate(Namespace.FDAPN, FdaPNKeys.ARRIVAL_LOCATION),
            filerCode: translate(Namespace.FDAPN, FdaPNKeys.FILER_CODE),
            billType: translate(Namespace.FDAPN, FdaPNKeys.BILL_TYPE),
            carrierCode: translate(Namespace.FDAPN, FdaPNKeys.CARRIER_CODE),
            entryType: translate(Namespace.FDAPN, FdaPNKeys.ENTRY_TYPE),
            mot: translate(Namespace.FDAPN, FdaPNKeys.MOT),
            envelopeNumber: translate(Namespace.FDAPN, FdaPNKeys.ENVELOPE_NUMBER),
            actionCode: translate(Namespace.FDAPN, FdaPNKeys.ACTION_CODE),
            status: translate(Namespace.FDAPN, FdaPNKeys.STATUS),
            submitterOfEntry: translate(Namespace.FDAPN, FdaPNKeys.SUBMITTER_OF_ENTRY),
            responseDatetime: translate(Namespace.FDAPN, FdaPNKeys.RESPONSE_DATETIME),
            sn: translate(Namespace.FDAPN, FdaPNKeys.SN),
            basketNumber: translate(Namespace.FDAPN, FdaPNKeys.BASKET_NUMBER),
            ultimateConsignee: translate(Namespace.FDAPN, FdaPNKeys.ULTIMATE_CONSIGNEE),
            createdBy: translate(Namespace.FDAPN, FdaPNKeys.CREATED_BY),
            createdDate: translate(Namespace.FDAPN, FdaPNKeys.CREATED_DATE),
            updatedBy: translate(Namespace.FDAPN, FdaPNKeys.UPDATED_BY),
            updatedDate: translate(Namespace.FDAPN, FdaPNKeys.UPDATED_DATE),
            submittedBy: translate(Namespace.FDAPN, FdaPNKeys.SUBMITTED_BY),
            submittedDate: translate(Namespace.FDAPN, FdaPNKeys.SUBMITTED_DATE),
            version: translate(Namespace.FDAPN, FdaPNKeys.VERSION),
            partyId: translate(Namespace.FDAPN, FdaPNKeys.PARTY_ID),
            saveProductFlag: translate(Namespace.FDAPN, FdaPNKeys.SAVE_PRODUCT_FLAG),
            productIdentification: translate(Namespace.FDAPN, FdaPNKeys.PRODUCT_IDENTIFICATION),
            productIdLookup: translate(Namespace.FDAPN, FdaPNKeys.PRODUCT_ID_LOOKUP),
            editFDAPN: translate(Namespace.FDAPN, FdaPNKeys.EDIT_FDAPN),
            responses: translate(Namespace.FDAPN, FdaPNKeys.RESPONSES),
            responsesFdapn: translate(Namespace.FDAPN, FdaPNKeys.RESPONSES_FDAPN),
            responsesSn: translate(Namespace.FDAPN, FdaPNKeys.RESPONSES_SN),
            statusNotification: translate(Namespace.FDAPN, FdaPNKeys.STATUS_NOTIFICATION),
            name: translate(Namespace.FDAPN, FdaPNKeys.NAME),
            snType: translate(Namespace.FDAPN, FdaPNKeys.SN_TYPE),
            phoneNumber: translate(Namespace.FDAPN, FdaPNKeys.PHONE_NUMBER),
            email: translate(Namespace.FDAPN, FdaPNKeys.EAMIL),
            submitSuccessMsg: translate(Namespace.FDAPN, FdaPNKeys.SUBMIT_SUCCESS_MSG),
            responseId: translate(Namespace.FDAPN, FdaPNKeys.RESPONSE_ID),
            responseDate: translate(Namespace.FDAPN, FdaPNKeys.RESPONSE_DATE),
            narrativeMsgType: translate(Namespace.FDAPN, FdaPNKeys.NARRATIVE_MSG_TYPE),
            narrativeMsg: translate(Namespace.FDAPN, FdaPNKeys.NARRATIVE_MSG),
            processingDate: translate(Namespace.FDAPN, FdaPNKeys.PROCESSING_DATE),
            remarks: translate(Namespace.FDAPN, FdaPNKeys.REMARKS),
            savePartyFlag: translate(Namespace.FDAPN, FdaPNKeys.SAVE_PARTY_FLAG),
            partyIdentification: translate(Namespace.FDAPN, FdaPNKeys.PARTY_IDENTIFICATION),
            partyIdLookup: translate(Namespace.FDAPN, FdaPNKeys.PARTY_ID_LOOKUP),
            productMaster: translate(Namespace.FDAPN, FdaPNKeys.PRODUCT_MASTER),
            uploadFDAPN: translate(Namespace.FDAPN, FdaPNKeys.UPLOAD_FDAPN),
            printFDAPN: translate(Namespace.FDAPN, FdaPNKeys.PRINT_FDAPN),
            submitMultipleRecords: translate(Namespace.FDAPN, FdaPNKeys.SUBMIT_MULTIPLE_RECORDS),

            govAgencyCode: translate(Namespace.FDAPN, FdaPNKeys.GOV_AGENCY_CODE),
            govAgencyProgramCode: translate(Namespace.FDAPN, FdaPNKeys.GOV_AGENCY_PROGRAM_CODE),
            pgaEntryLevelStatusCode: translate(Namespace.FDAPN, FdaPNKeys.PGA_ENTRY_LEVEL_STATUS_CODE),
            statusReasonCode: translate(Namespace.FDAPN, FdaPNKeys.STATUS_REASON_CODE),
            pgaLine: translate(Namespace.FDAPN, FdaPNKeys.PGA_LINE),
            refIdQualifier: translate(Namespace.FDAPN, FdaPNKeys.REF_ID_QUALIFIER),
            refIdNumber: translate(Namespace.FDAPN, FdaPNKeys.REF_ID_NUMBER),
            refIdReceiptDate: translate(Namespace.FDAPN, FdaPNKeys.REF_ID_RECEIPT_DATE),
            pgaLineSubReasonCode: translate(Namespace.FDAPN, FdaPNKeys.PGA_LINE_SUB_REASON_CODE),

            bolTitle: translate(Namespace.FDAPN, FdaPNKeys.Bol.TITLE),
            bolHeaderId: translate(Namespace.FDAPN, FdaPNKeys.Bol.BOL_HEADER_ID),
            bolBillType: translate(Namespace.FDAPN, FdaPNKeys.Bol.BILL_TYPE),
            bolIssuerCode: translate(Namespace.FDAPN, FdaPNKeys.Bol.BOL_ISSUER_CODE),
            bolNumber: translate(Namespace.FDAPN, FdaPNKeys.Bol.BOL_NUMBER),
            bolPnConfirmationNumber: translate(Namespace.FDAPN, FdaPNKeys.Bol.PN_CONFIRMATION_NUMBER),

            pgaTitle: translate(Namespace.FDAPN, FdaPNKeys.Pga.TITLE),
            pgaHeaderId: translate(Namespace.FDAPN, FdaPNKeys.Pga.PGA_HEADER_ID),
            pgaCommercialDesc: translate(Namespace.FDAPN, FdaPNKeys.Pga.COMMERCIAL_DESC),
            pgaGovAgencyProcessingCode: translate(Namespace.FDAPN, FdaPNKeys.Pga.GOV_AGENCY_PROCESSING_CODE),
            pgaIntendedUseCode: translate(Namespace.FDAPN, FdaPNKeys.Pga.INTENDED_USE_CODE),
            pgaIntendedUseDesc: translate(Namespace.FDAPN, FdaPNKeys.Pga.INTENDED_USE_DESC),
            pgaProductNumber: translate(Namespace.FDAPN, FdaPNKeys.Pga.PRODUCT_NUMBER),
            pgaRemarksCode: translate(Namespace.FDAPN, FdaPNKeys.Pga.REMARKS_CODE),
            pgaRemarksText: translate(Namespace.FDAPN, FdaPNKeys.Pga.REMARKS_TEXT),
            pgaLpcoNumber: translate(Namespace.FDAPN, FdaPNKeys.Pga.LPCO_NUMBER),
            pgaLpcoIssuer: translate(Namespace.FDAPN, FdaPNKeys.Pga.LPCO_ISSUER),
            pgaLpcoIssuerRegionalDesc: translate(Namespace.FDAPN, FdaPNKeys.Pga.LPCO_ISSUER_REGIONAL_DESC),
            pgaLpcoGovGeographicQualifier: translate(Namespace.FDAPN, FdaPNKeys.Pga.LPCO_GOV_GEOGRAPHIC_QUALIFIER),
            pgaLpcoIssuerLocation: translate(Namespace.FDAPN, FdaPNKeys.Pga.LPCO_ISSUER_LOCATION),
            pgaAnticipatedArrivalDate: translate(Namespace.FDAPN, FdaPNKeys.Pga.ANTICIPATED_ARRIVAL_DATE),
            pgaAnticipatedArrivalTime: translate(Namespace.FDAPN, FdaPNKeys.Pga.ANTICIPATED_ARRIVAL_TIME),
            pgaArrivalLocation: translate(Namespace.FDAPN, FdaPNKeys.Pga.ARRIVAL_LOCATION),
            pgaTotalQuantity: translate(Namespace.FDAPN, FdaPNKeys.Pga.TOTAL_QUANTITY),
            pgaUom: translate(Namespace.FDAPN, FdaPNKeys.Pga.UOM),
            pgaPackageTrackingCode: translate(Namespace.FDAPN, FdaPNKeys.Pga.PACKAGE_TRACKING_CODE),
            pgaPackageTrackingNumber: translate(Namespace.FDAPN, FdaPNKeys.Pga.PACKAGE_TRACKING_NUMBER),
            pgaCommodityCharacteristicDesc: translate(Namespace.FDAPN, FdaPNKeys.Pga.COMMODITY_CHARACTERISTIC_DESC),
            pgaCountryOfShipment: translate(Namespace.FDAPN, FdaPNKeys.Pga.COUNTRY_OF_SHIPMENT),
            pgaCountryOfProduction: translate(Namespace.FDAPN, FdaPNKeys.Pga.COUNTRY_OF_PRODUCTION),
            pgaPlaceOfGrowth: translate(Namespace.FDAPN, FdaPNKeys.Pga.PLACE_OF_GROWTH),
            pgaCountryOfEntryRefusal: translate(Namespace.FDAPN, FdaPNKeys.Pga.COUNTRY_OF_ENTRY_REFUSAL),
            pgaBaseUnitQuantity: translate(Namespace.FDAPN, FdaPNKeys.Pga.BASE_UNIT_QUANTITY),
            pgaBaseUnitUom: translate(Namespace.FDAPN, FdaPNKeys.Pga.BASE_UNIT_UOM),
            pgaPnConfirmationNumber: translate(Namespace.FDAPN, FdaPNKeys.Pga.PN_CONFIRMATION_NUMBER),
            pgaBasketNumber: translate(Namespace.FDAPN, FdaPNKeys.Pga.BASKET_NUMBER),
            pgaProductId: translate(Namespace.FDAPN, FdaPNKeys.Pga.PRODUCT_ID),
            pgaAdditionalPNDetails: translate(Namespace.FDAPN, FdaPNKeys.Pga.ADDITIONAL_PN_DETAILS),
            pgaAffirmationOfComplianceDetails: translate(Namespace.FDAPN, FdaPNKeys.Pga.AFFIRMATION_COMPLIANCE_DETAILS),
            pgaProductConditionDetails: translate(Namespace.FDAPN, FdaPNKeys.Pga.PRODUCT_CONDITION_DETAILS),
            baseUnit: translate(Namespace.FDAPN, FdaPNKeys.Pga.BASE_UNIT),
            baseQuantity: translate(Namespace.FDAPN, FdaPNKeys.Pga.BASE_UNIT_QUANTITY),
            baseUOM: translate(Namespace.FDAPN, FdaPNKeys.Pga.BASE_UNIT_UOM),
            totalQuantity: translate(Namespace.FDAPN, FdaPNKeys.Pga.TOTAL_QUANTITY),

            partyTitle: translate(Namespace.FDAPN, FdaPNKeys.Party.TITLE),
            partyPGA: translate(Namespace.FDAPN, FdaPNKeys.Party.PGA),
            partyPgaId: translate(Namespace.FDAPN, FdaPNKeys.Party.PARTY_PGA_ID),
            partyType: translate(Namespace.FDAPN, FdaPNKeys.Party.PARTY_TYPE),
            partyIdType: translate(Namespace.FDAPN, FdaPNKeys.Party.PARTY_ID_TYPE),
            partyIdTypeValue: translate(Namespace.FDAPN, FdaPNKeys.Party.PARTY_ID_TYPE_VALUE),
            partyName: translate(Namespace.FDAPN, FdaPNKeys.Party.NAME),
            partyAddress1: translate(Namespace.FDAPN, FdaPNKeys.Party.ADDRESS1),
            partyAddress2: translate(Namespace.FDAPN, FdaPNKeys.Party.ADDRESS2),
            partyAddress3: translate(Namespace.FDAPN, FdaPNKeys.Party.ADDRESS3),
            partyAddress4: translate(Namespace.FDAPN, FdaPNKeys.Party.ADDRESS4),
            partyAddress5: translate(Namespace.FDAPN, FdaPNKeys.Party.ADDRESS5),
            partyApartmentSuiteNumber: translate(Namespace.FDAPN, FdaPNKeys.Party.APARTMENT_SUITE_NUMBER),
            partyCityName: translate(Namespace.FDAPN, FdaPNKeys.Party.CITY_NAME),
            partyStateProvince: translate(Namespace.FDAPN, FdaPNKeys.Party.STATE_PROVINCE),
            partyPostalCode: translate(Namespace.FDAPN, FdaPNKeys.Party.POSTAL_CODE),
            partyCountryCode: translate(Namespace.FDAPN, FdaPNKeys.Party.COUNTRY_CODE),
            partyContactFirstName: translate(Namespace.FDAPN, FdaPNKeys.Party.CONTACT_FIRST_NAME),
            partyContactPhoneNumber: translate(Namespace.FDAPN, FdaPNKeys.Party.CONTACT_PHONE_NUMBER),
            partyEmail: translate(Namespace.FDAPN, FdaPNKeys.Party.EMAIL),

            additionalPartyDetails: translate(Namespace.FDAPN, FdaPNKeys.AddParty.TITLE),

            packagingTitle: translate(Namespace.FDAPN, FdaPNKeys.Packaging.TITLE),
            packagingPGA: translate(Namespace.FDAPN, FdaPNKeys.Packaging.PGA),
            packagingPGAId: translate(Namespace.FDAPN, FdaPNKeys.Packaging.PACKING_PGA_ID),
            packagingQualifier: translate(Namespace.FDAPN, FdaPNKeys.Packaging.PACKAGING_QUALIFIER),
            packagingQuantity: translate(Namespace.FDAPN, FdaPNKeys.Packaging.QUANTITY),
            packagingUOM: translate(Namespace.FDAPN, FdaPNKeys.Packaging.UOM),

            containerInfoTitle: translate(Namespace.FDAPN, FdaPNKeys.ContainerInfo.TITLE),
            containerInfoPGA: translate(Namespace.FDAPN, FdaPNKeys.ContainerInfo.PGA),
            containerInfoContainerNumber: translate(Namespace.FDAPN, FdaPNKeys.ContainerInfo.CONTAINER_NUMBER),

            affirmationTitle: translate(Namespace.FDAPN, FdaPNKeys.Affirmation.TITLE),
            affirmationCode: translate(Namespace.FDAPN, FdaPNKeys.Affirmation.COMPLIANCE_AFFIRMATION_CODE),
            affirmationDescription: translate(Namespace.FDAPN, FdaPNKeys.Affirmation.COMPLIANCE_AFFIRMATION_DESC),

            productCondTitle: translate(Namespace.FDAPN, FdaPNKeys.ProductCond.TITLE),
            productLOTNumber: translate(Namespace.FDAPN, FdaPNKeys.ProductCond.LOT_NUMBER),

            carrierCodeRegex: translate(Namespace.FDAPN, FdaPNKeys.validations.CARRIER_CODE_REGEX),
        }
    }

    const getTranslatedText = useCallback((namespace, key) => {
        if (!namespace || !key) return undefined

        return translation?.[namespace]?.[key] || undefined
    }, [])

    return (
        <FdaPNTranslationContext.Provider value={{ translation, getTranslatedText }}>
            {children}
        </FdaPNTranslationContext.Provider>
    )
}

export default FdaPNTranslationProvider

export { FdaPNTranslationContext, FdaPNTranslationConsumer }
